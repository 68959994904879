import * as THREE from "three";
import { ObjectAssign } from "../../compat";
import { EdgeShader } from "./EdgeShader";
import { NormalsShader } from "./NormalsShader";
import { createShaderMaterial } from "./ShaderUtils";

/**
 * Enumeration of important render targets generated by LMV.
 */
export const RenderTargets = {
  Color: 1,
  Depth: 2,
  ModelId: 3,
  Overlay: 4,
  Post1: 5,
  Post2: 6,
  SSAO: 7,
  PostDisplay: 8
};

// Create the depth target.
export function createDepthTarget(sw, sh, format, type, colorTarget) {
  var depthTarget = new THREE.WebGLRenderTarget(sw, sh,
  {
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: format,
    type: type,
    stencilBuffer: false
  });
  if (colorTarget) {

    depthTarget.shareDepthFrom = colorTarget;



  }
  depthTarget.name = "depthTarget";
  return depthTarget;
}


// Enable/Disable depthWrite for a material and all its override variants
export function setDepthWriteEnabled(material, enabled) {

  // Note that changing depthWrite does not need to set material.update to true.
  material.depthWrite = enabled;

  for (var i = 0; i < material.variants.length; i++) {
    var variant = material.variants[i];
    if (variant) {
      variant.depthWrite = enabled;
    }
  }
}

// Creates material for normal-depth shader - including alternative variants
// for instancing and with/without cutplanes.
export function createDepthMaterial() {

  // create main/default override material first
  var depthShader = NormalsShader;
  const _depthMaterial = createShaderMaterial(depthShader);
  _depthMaterial.blending = THREE.NoBlending;
  _depthMaterial.packedNormals = true;
  // normally the color target will write to the z-buffer, so depth does not need to do so.
  _depthMaterial.depthWrite = false;


















  // Flags to define alternative depth material variants.
  var DepthMaterialFlags = {
    NoCutPlanes: 0x01, // Without cutplanes to render section caps
    Instancing: 0x02, // Using instancing
    UnpackedNormals: 0x04, // Shape encodes normals as plain vec3
    Count: 0x08
  };

  // create special-case material variants
  var variants = [];
  variants[0] = null; // index 0 = null (=use default depthMaterial)
  for (var i = 1; i < DepthMaterialFlags.Count; i++) {
    var variant = _depthMaterial.clone();

    // Packed normals are LMV-specific, so they are missed by the THREE clone-function
    variant.packedNormals = _depthMaterial.packedNormals;

    // cutplanes: with/without
    if (i & DepthMaterialFlags.NoCutPlanes) {
      variant.cutplanes = null;
      variant.doNotCut = true; // make sure that cutplanes keep null (see MaterialManager.addMaterialNonHDR)
    }

    // instancing yes/no
    if (i & DepthMaterialFlags.Instancing) {
      variant.useInstancing = true;
    }

    // packed normals yes/no
    if (i & DepthMaterialFlags.UnpackedNormals) {
      variant.packedNormals = false;
    }

    variants[i] = variant;
  }

  _depthMaterial.variants = variants;

  // Define a custom override function: It decides for a shape
  // which depthMaterial variant will be used by WebGLRenderer.
  _depthMaterial.getCustomOverrideMaterial = function (shapeMaterial) {

    // If the original shape material has no cutplanes, use the alternative
    // _noCutplanesMaterial for normal/depth.
    var noCutPlanes = !shapeMaterial || !shapeMaterial.cutplanes || shapeMaterial.cutplanes.length == 0;

    // If the original material applies the instance transform, depthMaterial must do this as well.
    var instanced = shapeMaterial.useInstancing;

    // If original material doesn't use packed normals (as LMV does by default), switch it off for the depthMaterial as well
    var unpackedNormals = !shapeMaterial.packedNormals;

    // return the appropriate material variant
    var index =
    (noCutPlanes ? DepthMaterialFlags.NoCutPlanes : 0) | (
    instanced ? DepthMaterialFlags.Instancing : 0) | (
    unpackedNormals ? DepthMaterialFlags.UnpackedNormals : 0);
    return this.variants[index];
  };


  return _depthMaterial;
}

const _edgeColorHighlightUnder = new THREE.Vector4(1, 1, 1, 0.5);
const _edgeColorHighlight = new THREE.Vector4(1, 1, 1, 1);

export function createEdgeMaterial(state, edgeColor) {
  // create main/default override material first
  var edgeShader = EdgeShader;
  const _edgeMaterial = createShaderMaterial(edgeShader);
  _edgeMaterial.depthWrite = true;
  _edgeMaterial.depthTest = true;
  _edgeMaterial.isEdgeMaterial = true;
  _edgeMaterial.transparent = true;
  _edgeMaterial.blending = THREE.NormalBlending;
  _edgeMaterial.supportsMrtNormals = true;




































  // Flags to define alternative edge material variants.
  var EdgeMaterialFlags = {
    Instancing: 0x1, // Using instancing
    DoNotCut: 0x2,
    Count: 4
  };

  // create special-case material variants
  var variants = [];
  variants[0] = null; // index 0 = null (=use default edgeMaterial)
  for (var i = 1; i < EdgeMaterialFlags.Count; i++) {
    var variant = _edgeMaterial.clone();

    //Have to clone this manually, otherwise it's shared between the clones
    variant.defines = ObjectAssign({}, _edgeMaterial.defines);

    variant.isEdgeMaterial = true;
    variant.supportsMrtNormals = true;

    // instancing yes/no
    if (i & EdgeMaterialFlags.Instancing) {
      variant.useInstancing = true;
    }

    if (i & EdgeMaterialFlags.DoNotCut) {
      variant.doNotCut = true;
      variant.cutplanes = [];
    }

    variants[i] = variant;
  }

  _edgeMaterial.variants = variants;

  // Define a custom override function: It decides for a shape
  // which depthMaterial variant will be used by WebGLRenderer.
  _edgeMaterial.getCustomOverrideMaterial = function (shapeMaterial) {

    // If the original material applies the instance transform, depthMaterial must do this as well.
    var instanced = shapeMaterial.useInstancing;

    // return the appropriate material variant
    var index = instanced ? EdgeMaterialFlags.Instancing : 0;

    if (shapeMaterial.doNotCut) {
      index = index | EdgeMaterialFlags.DoNotCut;
    }

    var mat = this.variants[index] || _edgeMaterial;

    //Unlike depth test settings, we need to change uniforms on the material variant
    //for them to take effect
    if (state.isRenderingOverlays) {
      if (state.isRenderingHidden) {
        mat.uniforms.color.value.copy(_edgeColorHighlightUnder);
      } else {
        mat.uniforms.color.value.copy(_edgeColorHighlight);
      }
    } else {
      mat.uniforms.color.value.copy(edgeColor);
    }

    // Standard model materials usually use the default edge opacity.
    // But we allow custom shapes to override it.
    if (shapeMaterial.edgeOpacity !== undefined) {
      mat.uniforms.color.value.w = shapeMaterial.edgeOpacity;
    }

    mat.uniforms.color.needsUpdate = true;

    return mat;
  };

  return _edgeMaterial;
}

export function createIdTarget(ww, hh) {
  var target = new THREE.WebGLRenderTarget(ww, hh,
  { minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBAFormat,
    type: THREE.UnsignedByteType,
    stencilBuffer: false
  });
  target.texture.generateMipmaps = false;

  //Set this flag to avoid checking frame buffer status every time we read
  //a pixel from the ID buffer. We know the ID target is compatible with readPixels.
  target.canReadPixels = true;

  return target;
}

export function cubicBezier(p, t) {
  var cy = 3.0 * p[1];
  var by = 3.0 * (p[3] - p[1]) - cy;
  var ay = 1.0 - cy - by;

  return ((ay * t + by) * t + cy) * t;
}

export function setNoDepthNoBlend(pass) {
  pass.material.blending = THREE.NoBlending;
  pass.material.depthWrite = false;
  pass.material.depthTest = false;
}

// Helper function to copy array values
export function copyArray(srcArray, dstArray) {
  if (!srcArray || !dstArray) {
    return;
  }

  // Clean dst array.
  dstArray.length = 0;

  for (let i = 0; i < srcArray.length; i++) {
    dstArray[i] = srcArray[i];
  }
}