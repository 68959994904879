export default Object.freeze({
  // key is the file type. Use only lowercase
  // value is the viewer extension id. Must be one of the extensions registered in extensions/externalExtensions.js
  pdf: 'Autodesk.PDF',
  scalaris: 'Autodesk.Scalaris',
  dwf: 'Autodesk.DWF',
  dwfx: 'Autodesk.DWF',
  rcp: 'Autodesk.ReCap',
  gltf: 'Autodesk.glTF',
  glb: 'Autodesk.glTF'
});