import { ToolInterface } from "../../src/tools/ToolInterface";

const GlobalManagerMixin = Autodesk.Viewing.GlobalManagerMixin;

export class ExplodeTool extends ToolInterface {
  constructor(viewer) {var _this;
    super();_this = this;

    this.names = ['explode'];
    this.viewer = viewer;
    this.setGlobalManager(this.viewer.globalManager);
    this.active = false;

    this.activate = () => {
      this.active = true;
    };

    this.deactivate = function () {let reset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (reset)
      _this.setScale(0);
      _this.active = false;
    };

    this.isActive = () => {
      return this.active;
    };
  }

  setScale(value) {
    let scale = value;
    let options = this.viewer.getExplodeOptions();
    return this.viewer.explode(scale, options);
  }

  getScale() {
    return this.viewer.getExplodeScale();
  }

  setMagnitude(value) {
    let scale = this.viewer.getExplodeScale();
    let options = this.viewer.getExplodeOptions();
    options.magnitude = value;
    return this.viewer.explode(scale, options);
  }

  getMagnitude() {
    return this.viewer.getExplodeOptions().magnitude;
  }

  setDepthDampening(value) {
    let scale = this.viewer.getExplodeScale();
    let options = this.viewer.getExplodeOptions();
    options.depthDampening = value;
    return this.viewer.explode(scale, options);
  }

  getDepthDampening() {
    return this.viewer.getExplodeOptions().depthDampening;
  }
}

GlobalManagerMixin.call(ExplodeTool.prototype);