/**
 * @author bhouston / http://exocortex.com
 * @author WestLangley / http://github.com/WestLangley
 */
/* Pruned version of THREE.Box3, for use in the LMV web worker */

import { LmvVector3 } from './LmvVector3';

export let LmvBox3 = function (min, max) {

  this.min = min !== undefined ? min : new LmvVector3(Infinity, Infinity, Infinity);
  this.max = max !== undefined ? max : new LmvVector3(-Infinity, -Infinity, -Infinity);

};

LmvBox3.prototype = {

  constructor: LmvBox3,

  set: function (min, max) {

    this.min.copy(min);
    this.max.copy(max);

    return this;

  },

  setFromPoints: function (points) {

    this.makeEmpty();

    for (var i = 0, il = points.length; i < il; i++) {

      this.expandByPoint(points[i]);

    }

    return this;

  },

  setFromArray: function (array, offset) {

    this.min.x = array[offset];
    this.min.y = array[offset + 1];
    this.min.z = array[offset + 2];

    this.max.x = array[offset + 3];
    this.max.y = array[offset + 4];
    this.max.z = array[offset + 5];

    return this;

  },

  copyToArray: function (array, offset) {

    array[offset] = this.min.x;
    array[offset + 1] = this.min.y;
    array[offset + 2] = this.min.z;

    array[offset + 3] = this.max.x;
    array[offset + 4] = this.max.y;
    array[offset + 5] = this.max.z;

  },

  setFromCenterAndSize: function () {

    var v1 = new LmvVector3();

    return function (center, size) {

      var halfSize = v1.copy(size).multiplyScalar(0.5);

      this.min.copy(center).sub(halfSize);
      this.max.copy(center).add(halfSize);

      return this;

    };

  }(),

  clone: function () {

    return new this.constructor().copy(this);

  },

  copy: function (box) {

    this.min.copy(box.min);
    this.max.copy(box.max);

    return this;

  },

  makeEmpty: function () {

    this.min.x = this.min.y = this.min.z = Infinity;
    this.max.x = this.max.y = this.max.z = -Infinity;

    return this;

  },

  empty: function () {

    // this is a more robust check for empty than ( volume <= 0 ) because volume can get positive with two negative axes

    return this.max.x < this.min.x || this.max.y < this.min.y || this.max.z < this.min.z;

  },

  isEmpty: function () {
    // threejs backport - https://github.com/mrdoob/three.js/blob/dev/src/math/Box3.js
    return this.empty();
  },

  center: function (optionalTarget) {
    console.warn("LmvBox3.center() is deprecated. Use LmvBox3.getCenter() instead.");
    return this.getCenter(optionalTarget);
  },

  getCenter: function (optionalTarget) {

    var result = optionalTarget || new LmvVector3();
    return result.addVectors(this.min, this.max).multiplyScalar(0.5);

  },

  size: function (optionalTarget) {

    var result = optionalTarget || new LmvVector3();
    return result.subVectors(this.max, this.min);

  },

  // For compatibility with latest THREE
  getSize: function (optionalTarget) {

    var result = optionalTarget || new LmvVector3();
    return result.subVectors(this.max, this.min);

  },

  expandByPoint: function (point) {

    this.min.min(point);
    this.max.max(point);

    return this;

  },

  expandByVector: function (vector) {

    this.min.sub(vector);
    this.max.add(vector);

    return this;

  },

  expandByScalar: function (scalar) {

    this.min.addScalar(-scalar);
    this.max.addScalar(scalar);

    return this;

  },

  containsPoint: function (point) {

    if (point.x < this.min.x || point.x > this.max.x ||
    point.y < this.min.y || point.y > this.max.y ||
    point.z < this.min.z || point.z > this.max.z) {

      return false;

    }

    return true;

  },

  containsBox: function (box) {

    if (this.min.x <= box.min.x && box.max.x <= this.max.x &&
    this.min.y <= box.min.y && box.max.y <= this.max.y &&
    this.min.z <= box.min.z && box.max.z <= this.max.z) {

      return true;

    }

    return false;

  },

  getParameter: function (point, optionalTarget) {

    // This can potentially have a divide by zero if the box
    // has a size dimension of 0.

    var result = optionalTarget || new LmvVector3();

    return result.set(
      (point.x - this.min.x) / (this.max.x - this.min.x),
      (point.y - this.min.y) / (this.max.y - this.min.y),
      (point.z - this.min.z) / (this.max.z - this.min.z)
    );

  },

  isIntersectionBox: function (box) {

    // using 6 splitting planes to rule out intersections.

    if (box.max.x < this.min.x || box.min.x > this.max.x ||
    box.max.y < this.min.y || box.min.y > this.max.y ||
    box.max.z < this.min.z || box.min.z > this.max.z) {

      return false;

    }

    return true;

  },

  // For compatibility with latest THREE
  intersectsBox: function (box) {
    return this.isIntersectionBox(box);
  },

  clampPoint: function (point, optionalTarget) {

    var result = optionalTarget || new LmvVector3();
    return result.copy(point).clamp(this.min, this.max);

  },

  distanceToPoint: function () {

    var v1 = new LmvVector3();

    return function (point) {

      var clampedPoint = v1.copy(point).clamp(this.min, this.max);
      return clampedPoint.sub(point).length();

    };

  }(),

  intersect: function (box) {

    this.min.max(box.min);
    this.max.min(box.max);

    return this;

  },

  union: function (box) {

    this.min.min(box.min);
    this.max.max(box.max);

    return this;

  },

  applyMatrix4: function () {

    var points = [
    new LmvVector3(),
    new LmvVector3(),
    new LmvVector3(),
    new LmvVector3(),
    new LmvVector3(),
    new LmvVector3(),
    new LmvVector3(),
    new LmvVector3()];


    return function (matrix) {

      // NOTE: I am using a binary pattern to specify all 2^3 combinations below
      points[0].set(this.min.x, this.min.y, this.min.z).applyMatrix4(matrix); // 000
      points[1].set(this.min.x, this.min.y, this.max.z).applyMatrix4(matrix); // 001
      points[2].set(this.min.x, this.max.y, this.min.z).applyMatrix4(matrix); // 010
      points[3].set(this.min.x, this.max.y, this.max.z).applyMatrix4(matrix); // 011
      points[4].set(this.max.x, this.min.y, this.min.z).applyMatrix4(matrix); // 100
      points[5].set(this.max.x, this.min.y, this.max.z).applyMatrix4(matrix); // 101
      points[6].set(this.max.x, this.max.y, this.min.z).applyMatrix4(matrix); // 110
      points[7].set(this.max.x, this.max.y, this.max.z).applyMatrix4(matrix); // 111

      this.makeEmpty();
      this.setFromPoints(points);

      return this;

    };

  }(),

  translate: function (offset) {

    this.min.add(offset);
    this.max.add(offset);

    return this;

  },

  equals: function (box) {

    return box.min.equals(this.min) && box.max.equals(this.max);

  }

};